<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-18 11:55:12
 * @LastEditTime: 2020-04-30 17:55:19
 -->
<template>
	<div class="app-container" v-loading="loading">
		<el-form inline :model="searchForm" class="searchForm" size="small">
			<el-form-item label="培训课程">
				<el-select placeholder="请选择培训课程" filterable clearable size="small" v-model="searchForm.trainingCourseId">
					<el-option v-for="item in list2" :key="item.id" :value="item.id" :label="item.trainName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="培训开始时间">
				<el-date-picker
					v-model="searchForm.trainingStartDate"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [00:00:00]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.trainingEndDate"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [23:59:59]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-form-item label="发布时间">
				<el-date-picker
					v-model="searchForm.releaseStartDate"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [00:00:00]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.releaseEndDate"
					size="small"
					type="date"
					value-format="yyyy-MM-dd [23:59:59]"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" style="margin-left: 5px" @click="_getList(1)" icon="el-icon-search">查询</el-button>
			<div class="fr">
				<el-button
					type="primary"
					icon="el-icon-plus"
					size="small"
					@click="$router.push({ name: 'TrainDetail', query: { type: 1 } })"
					v-if="permissionControlBtns(pageName, 'Add')"
					>新增培训计划</el-button
				>
			</div>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
			<el-table-column
				v-for="(col, i) in columns"
				:prop="col.prop"
				:key="i"
				align="center"
				:label="col.label"
				:width="col.prop == 'trainingStartTime' || col.prop == 'trainingPublishTime' ? '150px' : ''"
			>
				<template v-slot="{ row }" v-if="col.prop == 'trainingCourse' || col.prop == 'trainingPlace'">
					<!--class="coursePlace"!-->
					<div :style="_returnStatu(row.trainingStartTime, { value: 1 }) && col.prop == 'trainingCourse' ? 'color: #ccc' : ''">
						{{ row[col.prop] }}
					</div>
				</template>
				<template v-slot="{ row }" v-else-if="col.prop == 'applicantsRegistrationNumber'">
					<el-link type="primary" @click="$router.push({ name: 'TrainMangementSignList', query: { planId: row.trainingCourseId } })">{{
						row.applicantsRegistrationNumber
					}}</el-link>
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" fixed="right">
				<template slot-scope="scope">
					<el-dropdown>
						<el-button type="text"><img src="../../assets/button/操作图标@3x.png" width="16px" /></el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								v-for="(item, i) in commands"
								@click.native.stop="_handleRowClick(scope.row, item)"
								:command="item"
								:key="i"
								v-show="
									_returnStatu(scope.row.trainingStartTime, item) &&
									permissionControlBtns(pageName, item.value === 1 ? 'Edit' : item.value === 2 ? 'Detail' : 'Copy')
								"
								>{{ item.label }}</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="margin: 20px 0; text-align: right"
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 50, 100, 200]"
			:page-size="searchForm.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.total"
		></el-pagination>
	</div>
</template>
<script>
import { trainingList, trainingName } from '@/api/trainMange.js';
export default {
	computed: {
		pageName() {
			return this.$route.name;
		}
	},
	data() {
		return {
			loading: false,
			list: [],
			list2: [],
			searchForm: {
				trainingStartDate: '',
				trainingEndDate: '',
				releaseStartDate: '',
				releaseEndDate: '',
				trainingCourseId: '',
				pageNo: 1,
				pageSize: 20,
				total: 0
			},
			columns: [
				{ label: '培训课程', prop: 'trainingCourse' },
				{ label: '报名人数', prop: 'registrationNumber' },
				{ label: '已报名人数', prop: 'applicantsRegistrationNumber' },
				{ label: '培训讲师', prop: 'trainingInstructors' },
				{ label: '培训形式', prop: 'trainingForm' },
				{ label: '培训开始时间', prop: 'trainingStartTime' },
				{ label: '培训地点', prop: 'trainingPlace' },
				{ label: '发布时间', prop: 'trainingPublishTime' }
			],
			commands: [
				{ label: '编辑', value: 1 },
				{ label: '详情', value: 2 },
				{ label: '副本', value: 3 }
			]
		};
	},
	methods: {
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			trainingList(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res.list;
				this.searchForm.total = res.totalNum;
			});
		},
		_getNameList() {
			trainingName({}).then((res) => {
				this.list2 = res;
			});
		},
		_handleRowClick(row, item) {
			if (item.value == 1) {
				this.$router.push({ name: 'TrainDetail', query: { type: 1, id: row.trainingCourseId } });
			} else if (item.value == 2) {
				this.$router.push({ name: 'TrainDetail', query: { type: 2, id: row.trainingCourseId } });
			} else {
				this.$router.push({ name: 'TrainDetail', query: { type: 1, id: row.trainingCourseId, copy: 1 } });
			}
		},
		_returnStatu(time, item) {
			if (item.value == 1) {
				return new Date(time).getTime() > new Date().getTime();
			}
			return true;
		}
	},
	mounted() {
		this._getNameList();
		this._getList();
	}
};
</script>
<style scoped lang="scss">
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.searchForm {
	overflow: hidden;
	margin: 10px 0 20px;
}
.coursePlace {
	width: 180px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}
</style>
